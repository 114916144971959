<template>
  <div>
    <Divider dashed style="color:#fff">租户分布</Divider>
    <div class="p-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">一类业态分布</h4>
      <div>
        <Table stripe size="small" :data="industryTypeData" :columns="typeColumns"></Table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      industryTypeData: [],
      typeColumns: [
        { title: '类型', key: 'name' },
        { title: '数量', align: 'center', key: 'count' },
        {
          title: '占比',
          align: 'right',
          key: 'rate',
          render: (h, params) => {
            return h('span', (params.row.rate * 100).toFixed(2) + '%')
          }
        }
      ]
    }
  }
}
</script>
